@keyframes flash {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    transorm: scale(1);
  }
  25%, 50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.outerContainer {
  width: 1700px;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #1A1A1D;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 8px;
  height: 60%;
  width: 35%;
  min-height: 400px;
}

.max-height {
  max-height: 500px;
  height: 500px;
}
.shrink {
  animation: shrink 1s 1 forwards;
}
.sideContainer {
  display: flex;
  flex-direction: column;
  width: 360px;
  max-height: 120px;
  align-items: center;
  color: white;
  position: relative;
}
.sideContainer.player-0 {
  top: 120px;
}
.sideContainer.player-1 {
  top: 480px;
}
.sideContainer.player-2 {
  top: 840px;
}
.sideContainer.player-3 {
  top: 1200px;
}
.sideContainer.player-4 {
  top: 1560px;
}
.sideContainer p {
  text-align: center;
}
.startButton {
  line-height: 0;
  text-transform: uppercase;
  text-decoration: none;
  background: #89c589;
  padding: 20px;
  display: inline-block;
  border: none;
  border-radius: 5px;
  margin: 2rem auto;
}
.startButton:hover {
  cursor: pointer;
}
.modal {
  color: black;
  text-align: center;
}
.rulesButton, .cheatsheetButton, .submitButton {
  line-height: 0;
  background: #c0b1f8;
  border: 0;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 5px;
}
.submitButton {
  background: #89c589;
}
.rulesButton:hover, .cheatsheetButton:hover, .submitButton:hover {
  cursor: pointer;
}

.attentionModal {
  display: flex;
  background: #c0b1f8;
  color: black;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;;
}

.square {
  background: #868600;
  width: 980px;
  height: 680px;
}

.item.flash {
  animation: flash 0.75s 1 forwards;
}
.item {
  background: white;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  display: inline-block;
  overflow: hidden;
  box-shadow: 0px 0px 8px #edffed;
  box-sizing: content-box;
}

.item img {
  width: 100%;
  height: 100%;
}

.item.poop {
  background: #8e5aca;
  box-shadow: 4px 4px 0 white;
  color: transparent;
}

.item.hidden {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.item.poop img {
  display: none;
}

.item.poop.sideways {
  transform: rotate(90deg)!important;
}

.player-space {
  background: #1A1A1D;
  width: 980px;
  margin: 1rem auto;
  height: 360px;
  text-align: center;
  border-right: 490px solid #c0b1f8;

}
.player-space span {
  margin-top: -3rem;
  font-size: 1.3rem;
  display: block;
}
.player-space p {
  padding-top: 1rem;
  font-size: 1.3rem;
  margin-right: -200%;
  pointer-events: none;
}

.game-over .player-space {
  opacity: 0;
  pointer-events: none;
}

.second-row {
  margin: 0 auto;
  display: flex;
}

.revealAll .item  {
  background: white!important;
  box-shadow: 0px 0px 8px #edffed;
}
.revealAll .item img {
  display: block!important;
}

.endGame {
  line-height: 0;
  background: #444444;
  border-radius: 5px;
  border: 0;
  color: white;
  padding: 2rem;
  margin: 2rem auto 0;
}
.endGame:hover {
  cursor: pointer;
}

.outerContainer.player-0 .player-space.player-0,
.outerContainer.player-1 .player-space.player-1,
.outerContainer.player-2 .player-space.player-2,
.outerContainer.player-3 .player-space.player-3,
.outerContainer.player-4 .player-space.player-4 {
  background: #e8c2d9;
}

.outerContainer.player-1 .player-0-private,
.outerContainer.player-2 .player-0-private,
.outerContainer.player-3 .player-0-private,
.outerContainer.player-0 .player-1-private,
.outerContainer.player-2 .player-1-private,
.outerContainer.player-3 .player-1-private,
.outerContainer.player-0 .player-2-private,
.outerContainer.player-1 .player-2-private,
.outerContainer.player-3 .player-2-private,
.outerContainer.player-0 .player-3-private,
.outerContainer.player-1 .player-3-private,
.outerContainer.player-2 .player-3-private {
  background: #5f4879;
  box-shadow: 4px 4px 0 white;
  color: transparent;
}

.outerContainer.player-1 .player-0-private img,
.outerContainer.player-2 .player-0-private img,
.outerContainer.player-3 .player-0-private img,
.outerContainer.player-0 .player-1-private img,
.outerContainer.player-2 .player-1-private img,
.outerContainer.player-3 .player-1-private img,
.outerContainer.player-0 .player-2-private img,
.outerContainer.player-1 .player-2-private img,
.outerContainer.player-3 .player-2-private img,
.outerContainer.player-0 .player-3-private img,
.outerContainer.player-1 .player-3-private img,
.outerContainer.player-2 .player-3-private img {
  display: none;
}

.rightTopSquare {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.3rem;
  width: 360px;
  height: 862px;
  color: white;
  text-align: center;
  padding-top: 40px;
}

.rightTopSquare div {
  text-align: right;
}

.rightTopSquare .bold {
  font-size: 1.6rem;
}

.finishedContainer {
  width: 490px;
  background: #c0b1f8;
  position: absolute;
  height: 1119px;
  top: 129px;
  left: 50%;
}

.finishedContainer.height-4 {
  height: 1492px;
}

.finishedContainer.height-5 {
  height: 1865px;
}

.finishedRow {
  border: 2px solid #1A1A1D;
  height: 373px;
  display: block;
}

.finishedRow p {
  text-align: center;
  font-size: 1.5rem;
}

input[type=number] {
  font-size: 2rem;
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1
}

.cardPointers {
  transform: rotate(-90deg);
  font-size: 1.3rem;
  color: white;
  position: absolute;
  margin-top: 131px;
  left: 175px;
}