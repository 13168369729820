html, body {
  font-family: 'Balsamiq Sans', 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
  background: #1A1A1D;
  font-size: 11px;
}
input[type=text], input[type=submit], button {
  font-family: 'Balsamiq Sans', 'Roboto', sans-serif;
  font-size: 11px;
}

#root {
  min-height: 100vh;
  background: #1A1A1D;
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  align-items: center;
  background-color: #1A1A1D;
}

.joinInnerContainer {
  width: 50%;
  max-width: 375px;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
}

.heading {
  color: white;
  font-size: 2.5em;
  padding-bottom: 10px;
  border-bottom: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin: 0 1rem;
}
.button {
  text-transform: uppercase;
  text-decoration: none;
  background: #c0b1f8;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  border: none;
  width: 100%;
}
.button:hover {
  cursor: pointer;
}

.mt-20 {
  margin-top: 20px;
}

.ml-20 {
  margin-left: 20px;
}

button:focus {
  outline: 0;
}

.playground-canvas {
  margin: 20px 0;
}

.extraButtonsRow {
  display: flex;
  justify-content: space-between;
}

.disclaimer {
  color: white;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #c0b1f8;
  font-size: 1.5rem;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 1;
}

.instructions, .images {
  display: flex;
  flex-direction: column;
}

.instructions span {
  display: inline-block;
  width: 40px;
  height: 60px;
  overflow: hidden;
  background: white;
  border-radius: 5px;
  margin: 1rem 1rem 0;
}
.instructions span img {
  width: 100%;
  height: auto;
}
.images img {
  width: 300px;
  margin: 2rem auto;
}

.closeModal {
  width: 6rem;
  height: 6rem;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  color: black;
  font-size: 1.5rem;
  right: 0;
}
.closeModal:hover {
  cursor: pointer;
}
